import React from "react"
import Header from "../components/header"
import Container from "../components/container"
import OuterContainer from "../components/outerContainer"
import Title from "../components/title"
import Footer from "../components/footer"

export default function Contact() {
  return (
    <OuterContainer>
      <Header />
      <Title text="Contact"/>
      <Container>
        <p>Send us a <a href="mailto:contact@taogidesign.com">message</a>!</p>
      </Container>
      <Footer />
    </OuterContainer>
  )
}